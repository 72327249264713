<template>
    <div class="basicInfo">
        <div class="title mt50 flex_r f_ai_c">
            <span>店铺管理</span>
            <el-button class="ml20" type="primary" @click="add">新增店铺</el-button>
            <el-button class="ml20" type="primary">
                <el-link class="lh_12" type="primary" style="color: #ffffff;" :href="fw_url" target="_blank">订购服务</el-link>
            </el-button>

        </div>
        <e-table ref="tables" @query-Table-data="handleCurrentChange" :tableCols="storeTableConf" :dataOrigin="dataOrigin" :total="total" :currentPage="currentPage">
            <template #shop_url="scope">
                <el-link class="lh_12" type="primary" :href="scope.rowData.shop_url" target="_blank">{{ scope.rowData.shop_url }}</el-link>

            </template>
            <template #operation="scope">
                <div class="cur_p">
                    <div @click="edit(scope.rowData.id)">编辑</div>
                    <div @click="bind(scope.rowData.id)">绑定授权</div>
                    <div @click="syncGoods(scope.rowData.id)">同步店铺商品</div>
                    <!--						<div @click="$router.push('/activeDetail')">查看详情</div>-->
                </div>
            </template>
        </e-table>
        <e-dialog width="800px" title="新增店铺" ref="dialogStoreRef" v-on:confirm="confirmShop">
            <el-form label-width="auto" inline>
                <div class="flex_r">
                    <div class="mr10">
                        <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :data="data"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                    <div>
                        <el-form-item label="店铺地址" class="w100" v-if="type == 'add'">
                            <div class="flex_r">
                                <el-input style="width: 300px" placeholder="请输入店铺地址" v-model="url"></el-input>
                                <el-button class="ml20" type="danger" @click="sync">同步信息</el-button>
                            </div>
                        </el-form-item>
                        <el-form-item label="店铺地址">
                            <el-input v-model="form.shop_url" style="width: 400px" placeholder="店铺地址"></el-input>
                        </el-form-item>
                        <el-form-item label="店铺名称">
                            <el-input v-model="form.shop_name"></el-input>
                        </el-form-item>
                        <el-form-item label="淘宝账户昵称">
                            <el-input v-model="form.ww_nick"></el-input>
                        </el-form-item>
                        <el-form-item label="授权码">
                            <el-input v-model="form.auth_token"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人">
                            <el-input v-model="form.username"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话">
                            <el-input v-model="form.mobile"></el-input>
                        </el-form-item>
                        <el-form-item label="qq">
                            <el-input v-model="form.qq"></el-input>
                        </el-form-item>
                        <el-form-item label="微信">
                            <el-input v-model="form.wx"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </e-dialog>
    </div>
</template>

<script>
    import {storeTableConf} from '@data/basicInfo';
    export default {
        data() {
            return {
                disabled: true,
                storeTableConf,
                origin_url: '',
                imageUrl: '',
                uploadUrl: '',
                dataOrigin: {
                    data: []
                },
                total: '',
                form: {},
                url: '',
                currentPage: 1,
                fw_url: 'https://fuwu.taobao.com/ser/detail.htm?spm=a1z13.8114210.1234-fwlb.181.yTuIYE&service_code=FW_GOODS-1000176741',
                type: 'add',
                id: '',
                data: {}
            }
        },
        methods: {
            syncGoods(id){
                let that = this
                this.$confirm('确定同步店铺商品?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.post('?server=live.anchor.shop.goods.lists.in.tb.get',{
                        id:id}).
                    then(function () {
                        //这里只会在接口是成功状态返回
                        //联网成功的回调
                        that.$message({
                            type: 'success',
                            message: '同步成功!'
                        });
                    }).catch(function (error) {
                        //这里只会在接口是失败状态返回，不需要去处理错误提示
                        console.log(error);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            handleCurrentChange(data){
                this.$refs.tables.loading = true
                this.currentPage = data.currentPage
                this.GetLists()
            },
            add(){
                this.id = ''
                this.form = {}
                this.type = 'add'
                this.$refs.dialogStoreRef.handleOpen()
            },
            bind(id){
                let that = this
                this.$confirm('确定绑定授权?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.post('?server=live.anchor.shop.bind.auth.token.post',{
                        id:id}).
                    then(function () {
                        //这里只会在接口是成功状态返回
                        //联网成功的回调
                        that.$message({
                            type: 'success',
                            message: '绑定成功!'
                        });
                    }).catch(function (error) {
                        //这里只会在接口是失败状态返回，不需要去处理错误提示
                        console.log(error);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            edit(id){
                let that = this
                that.form = {}
                that.$refs.dialogStoreRef.handleOpen()
                this.id = id
                this.type = 'edit'
                let loading = this.$loadings.service({
                    'text': "请求中",
                    background: 'rgba(28,28,28,0.7)',
                })
                that.get('?server=live.anchor.shop.item.get',{id: id}).
                then(function (response) {
                    //这里只会在接口是成功状态返回
                    //联网成功的回调
                    // console.log(response.data)
                    that.form = response.data
                    that.imageUrl = response.data.logo_url
                    that.origin_url = response.data.origin_url
                    that.form.nick = response.data.nick
                    that.form.shop_name = response.data.shop_name
                    that.form.shop_url = response.data.shop_url

                    that.form.ww_nick = response.data.ww_nick
                    that.form.auth_token = response.data.auth_token
                    that.form.username = response.data.username
                    that.form.mobile = response.data.mobile
                    that.form.qq = response.data.qq
                    that.form.wx = response.data.wx
                    loading.close()
                }).catch(function (error) {
                    loading.close()
                    that.$message.error('请求超时');
                    //这里只会在接口是失败状态返回，不需要去处理错误提示
                    console.log(error);
                });
            },
            sync(){
                let that = this
                let url = this.url
                if(url == '' || url == undefined){
                    that.$message.error('输入商品链接');
                    return
                }
                let loading = this.$loadings.service({
                    'text': "请求中",
                    background: 'rgba(28,28,28,0.7)',
                })
                that.get('?server=seller.shop.item.info.get',{goods_link: url, num_iid: url}).
                then(function (response) {
                    //这里只会在接口是成功状态返回
                    //联网成功的回调
                    // console.log(response.data)
                    that.form = response.data
                    that.form.shop_id = response.data.shop_id
                    that.form.seller_id = response.data.seller_id
                    that.form.nick = response.data.nick
                    that.form.ww_nick = response.data.nick
                    that.form.shop_name = response.data.shop_name
                    that.form.shop_type = response.data.shop_type
                    that.form.shop_url = response.data.shop_url
                    that.form.level_rank = response.data.level_rank
                    that.form.m_score = response.data.m_score
                    that.form.f_score = response.data.f_score
                    that.form.w_score = response.data.w_score
                    that.form.m_score_text = response.data.m_score_text
                    that.form.f_score_text = response.data.f_score_text
                    that.form.w_score_text = response.data.w_score_text
                    loading.close()
                }).catch(function (error) {
                    loading.close()
                    that.$message.error('请求超时');
                    //这里只会在接口是失败状态返回，不需要去处理错误提示
                    console.log(error);
                });
            },
            confirmShop(){
                let that = this
                if(JSON.stringify(this.form) == '{}'){
                    that.$message.error('请先同步店铺信息');
                    return false
                }
                if(this.form.shop_url == undefined || this.form.shop_url == ''){
                    that.$message.error('请输入店铺地址');
                    return false
                }
                if(this.form.shop_id == undefined || this.form.shop_id == ''){
                    that.$message.error('请完善店铺信息');
                    return false
                }
                let loading = this.$loadings.service({
                    'text': "请求中",
                    background: 'rgba(28,28,28,0.7)',
                })
                this.post('?server=live.anchor.shop.push.handle.post',{
                    id: this.id,
                    shop_url:this.form.shop_url,
                    nickname:this.form.shop_name,
                    imageUrl:this.imageUrl,
                    username:this.form.username,
                    shop_id: this.form.shop_id,
                    seller_id: this.form.seller_id,
                    nick: this.form.shop_name,
                    shop_type: this.form.shop_type,
                    level_rank: this.form.level_rank,
                    ww: this.form.ww_nick,
                    authToken: this.form.auth_token,
                    m_score: this.form.m_score,
                    f_score: this.form.f_score,
                    w_score: this.form.w_score,
                    m_score_text: this.form.m_score_text,
                    f_score_text: this.form.f_score_text,
                    w_score_text: this.form.w_score_text,
                    logo_url: this.origin_url,
                    qq:this.form.qq,
                    wx:this.form.wx,
                    tel: this.form.mobile,
                    mobile:this.form.mobile}).
                then(function (response) {
                    //这里只会在接口是成功状态返回
                    //联网成功的回调
                    loading.close()
                    that.$message.success(response.data.msg);
                    that.form = {}
                    that.$refs.tables.loading = true
                    // loading.close()
                    that.GetLists()
                    that.$refs.dialogStoreRef.handleClose()
                }).catch(function (error) {
                    //这里只会在接口是失败状态返回，不需要去处理错误提示
                    loading.close()
                    that.$message.error('请求超时');
                    console.log(error);
                });
            },
            handleAvatarSuccess(response){
                if (response.code === 4003) {
                    this.$message.error('您没有权限操作');

                    return false;
                }
                if(response.code == 0){
                    this.$message.error(response.msg);
                    return
                }
                this.$message.success(response.msg);
                this.imageUrl = response.data.url
                this.origin_url = response.data.origin_url
            },
            GetLists(){
                let that = this
                this.dataOrigin.data = []
                that.$refs.tables && that.$refs.tables.initData()
                this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
                this.get('?server=live.anchor.store.lists.get',{}).
                then(function (response) {
                    //这里只会在接口是成功状态返回
                    //联网成功的回调
                    // console.log(response)
                    let len = response.data.data.length
                    for(let i=0;i<len;i++){
                        // console.log(response.data.data[i])
                        that.dataOrigin.data.push(response.data.data[i])
                    }
                    that.total = response.data.total
                    that.$refs.tables.total = response.data.total
                    that.$refs.tables.loading = false
                    that.$refs.tables.initData()
                }).catch(function (error) {
                    //这里只会在接口是失败状态返回，不需要去处理错误提示
                    console.log(error);
                });
            },
        },
        created() {
            this.$nextTick(() => {
                let token = localStorage.getItem('token')
                this.data = {"auth_token": token};
                this.uploadUrl = this.appConfig.api_request_url+'?server=upload.file'
                this.$refs.tables.loading = true
                this.GetLists()
            });
        },
    }
</script>

<style lang="scss" scoped>
    .title {
        position: relative;
        line-height: 20px;
        padding-left: 20px;
        font-size: 16px;
    }
    .title:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0px;
        width: 6px;
        height: 22px;
        border-radius: 3px;
        background: #fc2628;
    }

</style>

<style lang="scss">
    // .basicInfo {
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    // .el-input.is-disabled .el-input__inner {
    // 	background-color: #fff;
    // 	border: none;
    // 	color: #000000;
    // 	cursor: text;
    // }
    // }
</style>